<template>
  <div class="container-fluid">
    <div>
      By clicking an icon name, you can copy the name of the icon ("icon-name")
    </div>
    <i class="icon-psh-warehouse2" />
    Icon Usages: (props: name, color, bg-color, size, round)
    <p-icon name="psh-warehouse2" color="primary" class="m-2" />
    <p-icon name="psh-warehouse2" color="lime" size="20px" class="m-2" />
    <p-icon name="psh-warehouse2" color="#FF0080" size="20px" class="m-2" />
    <p-icon name="psh-warehouse2" color="white" bg-color="primary" size="20px" class="m-2" />
    <p-icon name="psh-warehouse2" color="white" bg-color="#FF0080" round size="20px" class="m-2" />

    <div class="card-psh mt-2">
    <dx-util-text-box v-model="search" mode="search" class="w-25 p-1 m-1" />
  </div>
    <div class="d-flex flex-wrap">
      <div v-for="(iconName, index) in filteredIcons" :key="index" class="border m-2 d-flex flex-column" style="width:120px;">
        <div class="text-center icon display-4">
          <i :class="`icon-${iconName}`" />
        </div>
        <div role="button" class="text-center px-half bg-primary text-white flex-grow-1" @click="onCopy(iconName)">
          {{ iconName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconList from '@/assets/icons/icon-font/icons.json'

export default {
  data() {
    return {
      iconList,
      search: '',
    }
  },
  computed: {
    filteredIcons() {
      return Object.keys(this.iconList).filter(key => key.includes(this.search.toLowerCase()))
    },
  },
  methods: {
    async onCopy(iconName) {
      await navigator.clipboard.writeText(`icon-${iconName}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  line-height: 0;
  padding: 10px;
}
</style>
